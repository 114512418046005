<template>
  <div v-if="user" class="subscription-page">
    <b-button
      v-if="!isImpersonating"
      variant="primary"
      class="d-block logout-button"
      @click.prevent="logout"
    >
      <span>LogOut</span>
    </b-button>
    <div v-if="isImpersonating && !user.subscribed" class="text-center mb-4">
      <button
        class="btn btn-outline-dark btn-sm"
        :disabled="loadingStopImpersonate"
        @click="stopImpersonate"
      >
        <b-spinner v-if="loadingStopImpersonate" small />
        <i class="uil uil-sign-in-alt"></i> Main account
      </button>
    </div>
    <div v-if="!loadingPlans" class="row">
      <div v-for="plan in plans" :key="plan.id" class="col-lg-4 col-xl-3 col-md-4">
        <div class="card card-pricing">
          <div class="card-body p-4">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0 mb-2 font-size-16">{{ plan.name }}</h5>
                <h2 class="mt-0 mb-2"
                  >${{ plan.amount }}
                  <span class="font-size-14">/ <span class="text-capitalize">{{ plan.interval }}</span></span></h2
                >
              </div>
              <div class="align-self-center">
                <feather type="user" />
              </div>
            </div>

            <ul
              class="card-pricing-features text-muted border-top pt-2 mt-2 pl-0 list-unstyled"
            >
              <li
                v-for="item in plan.features.filter(
                  (f) => f.key === 'Number of Businesses'
                )"
                :key="item.key"
              >
                <i class="uil uil-check text-success font-size-15 mr-1"></i>
                {{ item.key }}: {{ item.value }}
              </li>
            </ul>

            <div class="mt-5 text-center">
              <button
                class="btn px-sm-4 btn-primary"
                :disabled="
                  loadingSubscription !== null || plan.id === user.stripe_plan
                "
                @click="getCheckoutLink(plan.id)"
              >
                <b-spinner v-if="loadingSubscription === plan.id" small />
                <span v-else>
                  <span v-if="plan.id === user.stripe_plan">Active</span>
                  <span v-else>
                    <i class="uil uil-arrow-right mr-1"></i>Buy Now for ${{
                      plan.amount
                    }}
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center mt-5">
      <b-spinner large></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingPlans: false,
      loadingSubscription: null,
      showAddCard: false,
      loadingCreateCard: false,
      selectedPlan: null,
      loadingPaymentMethod: false,
      loadingSetDefault: false,
      loadingStopImpersonate: false,
    }
  },

  computed: {
    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    plans() {
      let plans = this.$store.getters['subscription/allBusinessPlans'] || []
      
      return plans.filter(
        (plan) => plan.visible || plan.id === this.user.stripe_plan
      )
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.getPlans()
  },

  methods: {
    logout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {})
    },
    
    onSuccessHandler() {
      if (this.user.agency) {
        this.$router.push({ name: 'agency.businesses.index' })
      } else {
        this.getPlans()
      }
    },

    onFailedHandler() {},

    getPlans() {
      if (this.$store.getters['subscription/allBusinessPlans']) return

      this.loadingPlans = true
      this.$store
        .dispatch('subscription/getAllBusinessPlans')
        .then(() => {
          this.loadingPlans = false
        })
        .catch(() => {
          this.loadingPlans = false
        })
    },

    getCheckoutLink(plan) {
      this.loadingSubscription = plan

      this.$store
        .dispatch('subscription/getCheckoutLink', plan)
        .then((href) => {
          location.href = href
          this.loadingSubscription = null
        })
        .catch(() => {
          this.loadingSubscription = null
        })
    },

    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/admin')
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },
  },
}
</script>

<style lang="scss">
.subscription-page {
  .payment-methods {
    .payment-method {
      padding: 10px 0;
      .card-brand {
        font-size: 16px;
        font-weight: 500;
        color: #2c2e2f;
        text-transform: uppercase;
      }
      .card-bumber,
      .card-expiration {
        font-weight: 500;
        color: #666;
      }
    }
  }
  .payment-methods .payment-method .actions {
    margin-top: 10px;
  }
  .payment-methods .payment-method .actions a[disabled] {
    opacity: 0.6;
  }
  .logout-button {
    max-width: 200px;
    position: absolute;
    top: 50px;
    right: 50px;
  }
}
</style>
